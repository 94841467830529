import React, { useState, useEffect } from 'react';
import { fetchUserTickets } from '../supabaseClient';
import { useAuth } from '../AuthContext';
import { Link } from 'react-router-dom';
import { FaTicketAlt, FaMapMarkerAlt, FaCalendarAlt } from 'react-icons/fa';

const MyTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isAuthenticated } = useAuth();
  const [showUpcoming, setShowUpcoming] = useState(true);

  useEffect(() => {
    const loadTickets = async () => {
      setIsLoading(true);
      try {
        if (!isAuthenticated) {
          setIsLoading(false);
          return;
        }

        const userEmail = isAuthenticated?.user?.email || isAuthenticated?.userData?.email;
        
        if (!userEmail) {
          setError('No email found. Please update your profile with an email address.');
          setIsLoading(false);
          return;
        }

        const { data, error } = await fetchUserTickets(userEmail);
        if (error) throw error;
        
        setTickets(data || []);
        setError(null);
      } catch (err) {
        console.error('Error fetching tickets:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadTickets();
  }, [isAuthenticated]);

  const filterTickets = (tickets) => {
    const now = new Date();
    return tickets.filter(ticket => {
      const eventDate = new Date(ticket.events.start_date);
      return showUpcoming ? eventDate >= now : eventDate < now;
    });
  };

  const groupTicketsByEvent = (tickets) => {
    return tickets.reduce((acc, ticket) => {
      const eventId = ticket.event_id;
      if (!acc[eventId]) {
        acc[eventId] = {
          ...ticket,
          ticketCount: 1,
          ticketIds: [ticket.id],
          checkedInCount: ticket.checked_in ? 1 : 0
        };
      } else {
        acc[eventId].ticketCount += 1;
        acc[eventId].ticketIds.push(ticket.id);
        if (ticket.checked_in) {
          acc[eventId].checkedInCount += 1;
        }
      }
      return acc;
    }, {});
  };

  const filteredTickets = filterTickets(tickets);
  const groupedTickets = groupTicketsByEvent(filteredTickets);

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-4xl mx-auto">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
            <p className="mt-4 text-gray-600">Loading...</p>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-4xl mx-auto">
          <div className="animate-pulse space-y-4">
            {[1, 2, 3].map(i => (
              <div key={i} className="bg-white rounded-lg p-6 shadow-md">
                <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-6">My Tickets</h1>

        {/* Filter Buttons */}
        <div className="flex justify-center space-x-4 mb-6">
          <button
            onClick={() => setShowUpcoming(true)}
            className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
              showUpcoming 
                ? 'bg-blue-600 text-white' 
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Upcoming Events
          </button>
          <button
            onClick={() => setShowUpcoming(false)}
            className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
              !showUpcoming 
                ? 'bg-blue-600 text-white' 
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Past Events
          </button>
        </div>

        {error ? (
          <div className="text-center text-red-600 p-4 bg-white rounded-lg shadow">
            {error}
          </div>
        ) : Object.values(groupedTickets).length > 0 ? (
          <div className="space-y-4">
            {Object.values(groupedTickets).map(ticket => (
              <Link 
                key={ticket.id}
                to={`/ticket/${ticket.ticketIds[0]}`}
                state={{ allTicketIds: ticket.ticketIds }}
                className="block bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
              >
                <div className="p-6">
                  <div className="flex items-start justify-between">
                    <div className="flex-1">
                      <h2 className="text-xl font-bold mb-2">
                        {ticket.events.title}
                      </h2>
                      <h6 className="text-xl font-bold mb-2">
                        {ticket.events.gender_type} {ticket.events.event_type}
                      </h6>
                      <div className="space-y-2 text-gray-600">
                        <div className="flex items-center">
                          <FaMapMarkerAlt className="mr-2" />
                          <span>{ticket.events.location}</span>
                        </div>
                        <div className="flex items-center">
                          <FaCalendarAlt className="mr-2" />
                          <span>{new Date(ticket.events.start_date).toLocaleString()}</span>
                        </div>
                        <div className="flex items-center">
                          <div className="flex items-center">
                            <FaTicketAlt className="mr-2" />
                            <span>{ticket.ticket_type}</span>
                            <span className="ml-2 bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm font-semibold">
                              x{ticket.ticketCount}
                            </span>
                            <span className="ml-2 text-sm text-gray-600">
                              ({ticket.checkedInCount} / {ticket.ticketCount} checked in)
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {ticket.vendors?.logoURL && (
                      <img 
                        src={ticket.vendors.logoURL} 
                        alt="Vendor Logo" 
                        className="w-16 h-16 object-contain ml-4"
                      />
                    )}
                  </div>
                  {ticket.checked_in && (
                    <div className="mt-4 bg-green-100 text-green-700 px-4 py-2 rounded-lg text-center">
                      ✓ Checked In
                    </div>
                  )}
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <div className="text-center bg-white p-8 rounded-lg shadow">
            <FaTicketAlt className="mx-auto text-4xl text-gray-400 mb-4" />
            <p className="text-gray-600 mb-2">No {showUpcoming ? 'upcoming' : 'past'} tickets found</p>
            <p className="text-sm text-gray-500">
              Tickets you purchase will appear here
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyTickets;
