import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { checkAuth } from '../supabaseClient';
import Spinner from './common/Spinner';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const checkAuthentication = async () => {
      const auth = await checkAuth();
      setIsAuthenticated(auth.authenticated);
    };
    checkAuthentication();
  }, []);

  if (isAuthenticated === null) {
    return <Spinner/>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
