import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import MyTickets from "./pages/MyTickets";
import MyPasses from "./pages/MyPasses";
import MyAccountPage from "./pages/AccountPage";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./AuthContext";
import VendorPage from "./pages/VendorPage";
import EventPage from "./pages/EventPage";
import VendorLogin from "./pages/VendorLogin";
import VendorSignup from "./pages/VendorSignup";
import VendorDashboard from "./pages/VendorDashboard";
import CheckoutPage from "./components/CheckoutPage";
import TicketPage from './pages/TicketPage';
import ResetPassword from './pages/ResetPassword';
import NotFound from "./pages/NotFound";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/login"
            element={
              <Layout>
                <Login />
              </Layout>
            }
          />
          <Route
            path="/tickets"
            element={
              <ProtectedRoute>
                <Layout>
                  <MyTickets />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/passes"
            element={
              <ProtectedRoute>
                <Layout>
                  <MyPasses />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <Layout>
                  <MyAccountPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/vendor-login"
            element={
              <Layout>
                <VendorLogin />
              </Layout>
            }
          />
          <Route
            path="/vendor-signup"
            element={
              <Layout>
                <VendorSignup />
              </Layout>
            }
          />
          <Route
            path="/vendor-dashboard"
            element={
              <ProtectedRoute vendorOnly>
                {/* <Layout> */}
                  <VendorDashboard />
                {/* </Layout> */}
              </ProtectedRoute>
            }
          />

          {/* Checkout route with Layout */}
          <Route
            path="/checkout"
            element={
              <Layout>
                <CheckoutPage />
              </Layout>
            }
          />

          {/* Routes without Layout */}
          <Route path="/vendor/:id" element={<VendorPage />} />
          <Route path="/event/:id" element={<EventPage />} />
          <Route path="/ticket/:ticketId" element={<TicketPage />} />

          <Route
            path="/reset-password"
            element={
              <Layout>
                <ResetPassword />
              </Layout>
            }
          />

          {/* Add NotFound route at the end */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
