import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { FaFingerprint, FaMapMarkerAlt, FaCalendarAlt } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const TicketPage = () => {
  const { ticketId } = useParams();
  const location = useLocation();
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isChecking, setIsChecking] = useState(false);
  const [holdTimer, setHoldTimer] = useState(null);
  const [progress, setProgress] = useState(0);
  const [activeTicketId, setActiveTicketId] = useState(ticketId);
  const [currentSlide, setCurrentSlide] = useState(0);

  const fetchTickets = async () => {
    setIsLoading(true);
    try {
      // First, get the current ticket to get the event_id
      const { data: currentTicket, error: ticketError } = await supabase
        .from('tickets_sold')
        .select('event_id, email')
        .eq('id', ticketId)
        .single();
  
      if (ticketError) throw ticketError;
  
      // Then fetch all tickets for this event and email
      const { data, error } = await supabase
        .from('tickets_sold')
        .select(`
          *,
          events:event_id (
            title,
            location,
            start_date,
            event_type,
            gender_type,
            opponent
          ),
          vendors:vendor_id (
            company_name,
            short_name,
            primary_color,
            secondary_color,
            logoURL
          )
        `)
        .eq('event_id', currentTicket.event_id)
        .eq('email', currentTicket.email)
        .order('id', { ascending: true });
  
      if (error) throw error;
  
      // Custom sort:
      // 1. Main ticket first
      // 2. Rest of tickets in ID order
      const sortedTickets = [...(data || [])].sort((a, b) => {
        if (a.id === ticketId) return -1;
        if (b.id === ticketId) return 1;
        return 0; // Keep original order (which is sorted by ID)
      });
  
      setTickets(sortedTickets);
      setCurrentSlide(0); // Always start at the first slide (main ticket)
    } catch (error) {
      console.error('Error fetching tickets:', error);
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    fetchTickets();
  }, []);

  const handleHoldStart = () => {
    setIsChecking(true);
    let progressValue = 0;
    const timer = setInterval(() => {
      progressValue += 2;
      setProgress(progressValue);
      
      if (progressValue >= 100) {
        clearInterval(timer);
        checkInTicket();
      }
    }, 40); // 2 seconds total (50 * 40ms = 2000ms)
    
    setHoldTimer(timer);
  };

  const handleHoldEnd = () => {
    if (holdTimer) {
      clearInterval(holdTimer);
      setHoldTimer(null);
    }
    setIsChecking(false);
    setProgress(0);
  };

  const checkInTicket = async () => {
    try {
      const now = new Date().toISOString();
      const { error } = await supabase
        .from('tickets_sold')
        .update({ 
          checked_in: true,
          checked_in_at: now
        })
        .eq('id', activeTicketId);

      if (error) throw error;

      // Update the local state with the new timestamp
      setTickets(tickets.map(ticket => 
        ticket.id === activeTicketId 
          ? { ...ticket, checked_in: true, checked_in_at: now }
          : ticket
      ));
      
      setIsChecking(false);
      setProgress(0);
    } catch (error) {
      console.error('Error checking in ticket:', error);
    }
  };

  if (isLoading) {
    return <div className="loading-spinner" />;
  }

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 select-none">
      {/* Ticket Counter */}
      <div className="text-center mb-4 text-gray-600 font-semibold">
        Ticket {currentSlide + 1} of {tickets.length}
      </div>

      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        centeredSlides={true}
        className="max-w-lg mx-auto"
        onSlideChange={(swiper) => {
          setActiveTicketId(tickets[swiper.activeIndex].id);
          setCurrentSlide(swiper.activeIndex);
        }}
        initialSlide={currentSlide}
      >
        {tickets.map((ticket) => (
          <SwiperSlide key={ticket.id} className="select-none">
            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
              {/* Checked/Unchecked Indicator */}
              <div className={`w-full h-1 ${ticket.checked_in ? 'bg-green-500' : 'bg-blue-500'}`} />
              
              <div className="bg-blue-600 text-white p-6">
                <div className="flex justify-between items-start mb-4">
                  <h1 className="text-2xl font-bold">
                    {ticket.events.title}
                  </h1>
                  <span className="bg-white text-blue-600 px-3 py-1 rounded-lg text-sm font-mono font-bold uppercase">
                    #{ticket.id.slice(-5)}
                  </span>
                </div>
                <p className="mt-2">{ticket.events.gender_type} {ticket.events.event_type}</p>
              </div>

              <div className="p-6 space-y-4">
                <div className="space-y-3">
                  <div className="flex items-center text-gray-600">
                    <FaMapMarkerAlt className="mr-2 flex-shrink-0" />
                    <span>{ticket.events.location}</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <FaCalendarAlt className="mr-2 flex-shrink-0" />
                    <span>{new Date(ticket.events.start_date).toLocaleString()}</span>
                  </div>
                </div>

                {!ticket.checked_in ? (
                  <div className="mt-6">
                    <div className="relative">
                      <button
                        onMouseDown={handleHoldStart}
                        onMouseUp={handleHoldEnd}
                        onMouseLeave={handleHoldEnd}
                        onTouchStart={handleHoldStart}
                        onTouchEnd={handleHoldEnd}
                        disabled={ticket.checked_in}
                        className="w-full bg-blue-600 text-white py-4 rounded-lg flex items-center justify-center space-x-2 relative overflow-hidden"
                      >
                        <FaFingerprint className="text-2xl" />
                        <span>Press and Hold to Check In</span>
                        {isChecking && (
                          <div 
                            className="absolute bottom-0 left-0 h-1 bg-blue-300 transition-all duration-100"
                            style={{ width: `${progress}%` }}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="mt-6 text-center p-4 bg-green-100 rounded-lg">
                    <p className="text-green-600 font-semibold">✓ Checked In</p>
                    <p className="text-sm text-green-500">
                      {new Date(ticket.checked_in_at).toLocaleString()}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      
      {tickets.length > 1 && (
        <div className="text-center mt-4 text-gray-600">
          Swipe to view more tickets
        </div>
      )}
    </div>
  );
};

export default TicketPage; 