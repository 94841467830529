import React from 'react';
import { ClipLoader } from 'react-spinners';


const Spinner = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-[#1d4ed8]">
      <ClipLoader color="#ffffff" size={60} />
    </div>
  );
};

export default Spinner;
