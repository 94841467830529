import React from 'react';

const MyPasses = () => {
  return (
    <div className="min-h-screen bg-white">
    
      <main className="flex justify-center items-center ">
        <div className="w-full max-w-4xl bg-white  p-6 text-center">
          <h1 className="text-3xl font-bold mb-6">MY PASSES</h1>

          <div className="mb-6">
            <p className="text-gray-500">Missing Passes?</p>
            <p className="text-gray-500">
              You may need to add your email or phone number to your profile
            </p>
            <a
              href="#"
              className="text-blue-600 font-semibold hover:underline"
            >
              Update Profile
            </a>
          </div>

          <div className="flex justify-center space-x-4">
            <button className="bg-white text-blue-600 font-semibold border border-blue-600 rounded-lg py-2 px-4 hover:bg-blue-50">
              All Upcoming
            </button>
            <button className="bg-blue-600 text-white font-semibold rounded-lg py-2 px-4 hover:bg-blue-700">
              Refresh Passes
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MyPasses;
