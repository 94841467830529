import React, { useState, useEffect } from 'react';
import { supabase, createStripeAccount, checkStripeAccountStatus } from '../../supabaseClient';
import Spinner from '../common/Spinner';
import Toast from '../common/Toast';
import { FaCog, FaPalette, FaStripe, FaEdit, FaTimes, FaCheck, FaSync } from 'react-icons/fa';
import SkeletonLoader from '../common/SkeletonLoader';
import AddressAutocomplete from '../common/AddressAutocomplete';

const getStatusColor = (status) => {
  switch (status) {
    case 'complete':
      return 'text-green-600';
    case 'pending':
    case 'incomplete':
      return 'text-yellow-600';
    default:
      return 'text-red-600';
  }
};

const getButtonText = (status) => {
  switch (status) {
    case 'pending':
      return 'Check Status';
    case 'incomplete':
      return 'Finish Connection';
    default:
      return 'Connect with Stripe';
  }
};

const SettingsTab = ({ vendorId }) => {
  const [vendor, setVendor] = useState(null);
  const [isEditAccountModalOpen, setIsEditAccountModalOpen] = useState(false);
  const [isEditBrandingModalOpen, setIsEditBrandingModalOpen] = useState(false);
  const [accountData, setAccountData] = useState({
    company_name: '',
    first_name: '',
    last_name: '',
    address: '',
    contact_email: '',
    contact_phone: '',
    description: '',
    mascotname: '',
    short_name: '',
  });
  const [brandingData, setBrandingData] = useState({
    primary_color: '',
    secondary_color: '',
    logoURL: '',
  });
  const [logoFile, setLogoFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [stripeAccountId, setStripeAccountId] = useState(null);
  const [stripeAccountStatus, setStripeAccountStatus] = useState(null);
  const [toast, setToast] = useState({ show: false, message: '', type: 'success' });
  const [error, setError] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [isConnectingStripe, setIsConnectingStripe] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [stripeAccountDetails, setStripeAccountDetails] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    const fetchVendorData = async () => {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('vendors')
        .select('*')
        .eq('id', vendorId)
        .single();

      if (error) {
        console.error('Error fetching vendor data:', error.message);
      } else {
        setVendor(data);
        setAccountData({
          company_name: data.company_name,
          first_name: data.first_name,
          last_name: data.last_name,
          address: data.address,
          contact_email: data.contact_email,
          contact_phone: data.contact_phone,
          description: data.description,
          mascotname: data.mascotname,
          short_name: data.short_name,
        });
        setBrandingData({
          primary_color: data.primary_color,
          secondary_color: data.secondary_color,
          logoURL: data.logoURL,
        });
      }
      setIsLoading(false);
    };

    fetchVendorData();
  }, [vendorId]);

  useEffect(() => {
    const fetchStripeAccount = async () => {
      const { data, error } = await supabase
        .from('vendors')
        .select('stripe_account_id, stripe_account_status')
        .eq('id', vendorId)
        .single();

      if (!error && data) {
        setStripeAccountId(data.stripe_account_id);
        setStripeAccountStatus(data.stripe_account_status);
      }
    };

    fetchStripeAccount();
  }, [vendorId]);

  useEffect(() => {
    if (stripeAccountId) {
      checkDetailedStatus();
    }
  }, [stripeAccountId]);

  const openEditAccountModal = () => setIsEditAccountModalOpen(true);
  const closeEditAccountModal = () => setIsEditAccountModalOpen(false);

  const openEditBrandingModal = () => setIsEditBrandingModalOpen(true);
  const closeEditBrandingModal = () => setIsEditBrandingModalOpen(false);

  const handleAccountChange = (e) => {
    const { name, value } = e.target;
    setAccountData({ ...accountData, [name]: value });
  };

  // Define handleBrandingChange
  const handleBrandingChange = (e) => {
    const { name, value } = e.target;
    setBrandingData({ ...brandingData, [name]: value });
  };

  const showToast = (message, type = 'success') => {
    setToast({ show: true, message, type });
    setTimeout(() => {
      setToast({ show: false, message: '', type: 'success' });
    }, 3000);
  };

  const saveAccountChanges = async () => {
    try {
      const { error } = await supabase
        .from('vendors')
        .update(accountData)
        .eq('id', vendorId);

      if (error) throw error;

      setVendor({ ...vendor, ...accountData });
      closeEditAccountModal();
      showToast('Account details updated successfully!');
    } catch (error) {
      showToast(error.message, 'error');
    }
  };

  const saveBrandingChanges = async () => {
    let updatedLogoURL = brandingData.logoURL;

    if (logoFile) {
      setIsUploading(true);
      
      try {
        // Create a unique file path
        const fileName = `${vendorId}/${Date.now()}_${logoFile.name}`;
      

        // Upload the file
       
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from('logos')
          .upload(fileName, logoFile);

        if (uploadError) {
          console.error('Upload error:', uploadError);
          throw uploadError;
        }

        // Get the public URL
        const { data: publicUrlData } = supabase.storage
          .from('logos')
          .getPublicUrl(fileName);


        if (publicUrlData) {
          updatedLogoURL = publicUrlData.publicUrl;
        }

        // Update the branding data with new URL
        const updatedBrandingData = {
          ...brandingData,
          logoURL: updatedLogoURL,
        };
       

        // Save to database
      
        const { data: updateData, error: updateError } = await supabase
          .from('vendors')
          .update(updatedBrandingData)
          .eq('id', vendorId);

        if (updateError) {
          console.error('Database update error:', updateError);
          throw updateError;
        }

       

        // Update local state
        setVendor({ ...vendor, ...updatedBrandingData });
        setBrandingData(updatedBrandingData);
      

        closeEditBrandingModal();
        showToast('Branding updated successfully!');
       
      } catch (error) {
        
        showToast(error.message, 'error');
      } finally {
        setIsUploading(false);
        
      }
    } else {
      try {
        // Just update colors without logo change
        const { error: updateError } = await supabase
          .from('vendors')
          .update(brandingData)
          .eq('id', vendorId);

        if (updateError) throw updateError;

        setVendor({ ...vendor, ...brandingData });
        closeEditBrandingModal();
        showToast('Branding updated successfully!');
      } catch (error) {
        
        showToast(error.message, 'error');
      }
    }
  };

  const handleLogoFileChange = (event) => {
    const file = event.target.files[0];
    
    if (file) {
      // Log file details
      
      
      // Validate file type
      if (!file.type.startsWith('image/')) {
        console.error('Invalid file type:', file.type);
        showToast('Please select an image file', 'error');
        return;
      }

      // Validate file size (e.g., max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        console.error('File too large:', file.size);
        showToast('File size should be less than 5MB', 'error');
        return;
      }

      setLogoFile(file);
      setSelectedFileName(file.name);
      
    }
  };

  const handleStripeConnect = async () => {
    setIsConnectingStripe(true);
    try {
      // If the account is incomplete, fetch the account link to finish the setup
      if (stripeAccountStatus === 'incomplete') {
        const { accountLink } = await checkStripeAccountStatus(stripeAccountId);
  
        if (accountLink) {
          // Redirect the user to complete the setup
          window.location.href = accountLink;
          return;
        }
      }
  
      // For new connections, create a Stripe account
      const { url, status } = await createStripeAccount(
        vendorId,
        vendor.contact_email,
        vendor.company_name
      );
  
      if (status === 'complete') {
        setStripeAccountStatus('complete');
        showToast('Stripe account is already complete and active!', 'success');
      } else if (url) {
        window.location.href = url;
      } else {
        throw new Error('Failed to connect with Stripe');
      }
    } catch (error) {
      console.error('Error connecting to Stripe:', error);
      showToast('Failed to connect Stripe account', 'error');
    } finally {
      setIsConnectingStripe(false);
    }
  };
  

  const checkDetailedStatus = async () => {
    try {
      setIsConnectingStripe(true);
      if (!stripeAccountId) return;
  
      const status = await checkStripeAccountStatus(stripeAccountId);
      setStripeAccountDetails(status);
      
      // Update both local state and database status
      const newStatus = status.isComplete ? 'complete' : 'incomplete';
      setStripeAccountStatus(newStatus);

      // Update the vendor's stripe status in the database
      const { error: updateError } = await supabase
        .from('vendors')
        .update({ stripe_account_status: newStatus })
        .eq('id', vendorId);

      if (updateError) {
        console.error('Error updating stripe status:', updateError);
        throw updateError;
      }

      if (status.isComplete) {
        showToast('Stripe account setup is complete!', 'success');
      } else if (status.accountLink) {
        showToast('Incomplete setup. Follow the link to complete the process.', 'warning');
      }
    } catch (error) {
      console.error('Error checking status:', error);
      showToast('Failed to check account status', 'error');
    } finally {
      setIsConnectingStripe(false);
    }
  };
  

  const InfoField = ({ label, value }) => (
    <div>
      <label className="block text-sm font-medium text-gray-500 mb-1">{label}</label>
      <p className="text-gray-900 font-medium">{value || '-'}</p>
    </div>
  );

  const AccountDetailsSkeleton = () => (
    <div className="bg-white rounded-xl shadow-lg mb-6 overflow-hidden">
      <div className="bg-gradient-to-r from-blue-500 to-blue-700 p-4 flex justify-between items-center">
        <div className="flex items-center text-white">
          <FaCog className="text-2xl mr-3" />
          <h3 className="text-xl font-bold">Account Details</h3>
        </div>
      </div>
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {[...Array(9)].map((_, index) => (
            <div key={index} className="space-y-2">
              <div className="h-4 bg-gray-200 rounded-full w-24 animate-pulse"></div>
              <div className="h-6 bg-gray-200 rounded-full w-48 animate-pulse"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const BrandingSkeleton = () => (
    <div className="bg-white rounded-xl shadow-lg mb-6 overflow-hidden">
      <div className="bg-gradient-to-r from-purple-500 to-purple-700 p-4 flex justify-between items-center">
        <div className="flex items-center text-white">
          <FaPalette className="text-2xl mr-3" />
          <h3 className="text-xl font-bold">Branding</h3>
        </div>
      </div>
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[...Array(2)].map((_, index) => (
            <div key={index}>
              <div className="h-4 bg-gray-200 rounded-full w-24 mb-2 animate-pulse"></div>
              <div className="flex items-center">
                <div className="w-10 h-10 bg-gray-200 rounded-lg animate-pulse"></div>
                <div className="h-4 bg-gray-200 rounded-full w-20 ml-3 animate-pulse"></div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-6">
          <div className="h-4 bg-gray-200 rounded-full w-16 mb-2 animate-pulse"></div>
          <div className="h-20 w-20 bg-gray-200 rounded-lg animate-pulse"></div>
        </div>
      </div>
    </div>
  );

  const StripeSkeleton = () => (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="bg-gradient-to-r from-indigo-500 to-indigo-700 p-4">
        <div className="flex items-center text-white">
          <FaStripe className="text-2xl mr-3" />
          <h3 className="text-xl font-bold">Payment Processing</h3>
        </div>
      </div>
      <div className="p-6">
        <div className="space-y-4">
          <SkeletonLoader />
        </div>
      </div>
    </div>
  );

  return (
    <div className="p-6">
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ show: false, message: '', type: 'success' })}
        />
      )}

      <h2 className="text-2xl font-bold mb-6">Settings</h2>

      {isLoading ? (
        <>
          <AccountDetailsSkeleton />
          <BrandingSkeleton />
          <StripeSkeleton />
        </>
      ) : (
        <>
          {/* Account Details Card */}
          <div className="bg-white rounded-xl shadow-lg mb-6 overflow-hidden">
            <div className="bg-gradient-to-r from-blue-500 to-blue-700 p-4 flex justify-between items-center">
              <div className="flex items-center text-white">
                <FaCog className="text-2xl mr-3" />
                <h3 className="text-xl font-bold">Account Details</h3>
              </div>
              <button
                onClick={() => setIsEditAccountModalOpen(true)}
                className="p-2 text-white hover:bg-blue-600 rounded-full transition-colors duration-200"
              >
                <FaEdit className="w-5 h-5" />
              </button>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InfoField label="Company Name" value={vendor?.company_name} />
                <InfoField label="First Name" value={vendor?.first_name} />
                <InfoField label="Last Name" value={vendor?.last_name} />
                <InfoField label="Email" value={vendor?.contact_email} />
                <InfoField label="Phone" value={vendor?.contact_phone} />
                <InfoField label="Address" value={vendor?.address} />
                <InfoField label="Mascot Name" value={vendor?.mascotname} />
                <InfoField label="Short Name" value={vendor?.short_name} />
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Convenience Fee
                  </label>
                  <div className="mt-1 p-3 bg-gray-50 border border-gray-200 rounded-md">
                    <span className="text-gray-700">{`$${vendor?.convenience_fee || '1.30'} per ticket`}</span>
                    <p className="mt-1 text-sm text-gray-500">
                      This fee is applied to each paid ticket sold through your events
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Branding Card */}
          <div className="bg-white rounded-xl shadow-lg mb-6 overflow-hidden">
            <div className="bg-gradient-to-r from-purple-500 to-purple-700 p-4 flex justify-between items-center">
              <div className="flex items-center text-white">
                <FaPalette className="text-2xl mr-3" />
                <h3 className="text-xl font-bold">Branding</h3>
              </div>
              <button
                onClick={() => setIsEditBrandingModalOpen(true)}
                className="p-2 text-white hover:bg-purple-600 rounded-full transition-colors duration-200"
              >
                <FaEdit className="w-5 h-5" />
              </button>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-500 mb-2">Primary Color</label>
                  <div className="flex items-center">
                    <div
                      className="w-10 h-10 rounded-lg shadow-inner"
                      style={{ backgroundColor: vendor?.primary_color }}
                    />
                    <span className="ml-3 font-medium">{vendor?.primary_color}</span>
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-500 mb-2">Secondary Color</label>
                  <div className="flex items-center">
                    <div
                      className="w-10 h-10 rounded-lg shadow-inner"
                      style={{ backgroundColor: vendor?.secondary_color }}
                    />
                    <span className="ml-3 font-medium">{vendor?.secondary_color}</span>
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <label className="block text-sm font-medium text-gray-500 mb-2">Logo</label>
                {vendor?.logoURL ? (
                  <img src={vendor.logoURL} alt="Vendor Logo" className="h-20 object-contain" />
                ) : (
                  <div className="h-20 w-20 bg-gray-100 rounded-lg flex items-center justify-center">
                    <span className="text-gray-400">No logo</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Stripe Integration Card */}
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="bg-gradient-to-r from-indigo-500 to-indigo-700 p-4">
              <div className="flex items-center text-white">
                <FaStripe className="text-2xl mr-3" />
                <h3 className="text-xl font-bold">Payment Processing</h3>
              </div>
            </div>
            <div className="p-6">
              {!stripeAccountId ? (
                <div>
                  <p className="text-gray-600 mb-4">Connect with Stripe to start accepting payments for your tickets.</p>
                  <button
                    onClick={handleStripeConnect}
                    disabled={isConnectingStripe}
                    className={`px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors duration-200 flex items-center ${
                      isConnectingStripe ? 'opacity-75 cursor-not-allowed' : ''
                    }`}
                  >
                    {isConnectingStripe ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Connecting to Stripe...
                      </>
                    ) : (
                      <>
                        <FaStripe className="mr-2" />
                        Connect with Stripe
                      </>
                    )}
                  </button>
                </div>
              ) : (
                
  <div className="space-y-4">
    {/* Account Status */}
    <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
      <div className="flex justify-between items-center mb-2">
        <h4 className="font-semibold text-lg text-gray-700">Account Status</h4>
        <button
          onClick={checkDetailedStatus}
          disabled={isConnectingStripe}
          className={`p-2 text-gray-600 hover:text-blue-600 transition-colors duration-200 ${
            isConnectingStripe ? 'animate-spin' : ''
          }`}
          title="Refresh Status"
        >
          <FaSync className="w-5 h-5" />
        </button>
      </div>
      {stripeAccountDetails ? (
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <div
              className={`w-3 h-3 rounded-full ${
                stripeAccountDetails.detailsSubmitted ? 'bg-green-500' : 'bg-red-500'
              }`}
            />
            <span className="text-gray-600">
              Account Details: <strong>{stripeAccountDetails.detailsSubmitted ? 'Submitted' : 'Incomplete'}</strong>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <div
              className={`w-3 h-3 rounded-full ${
                stripeAccountDetails.chargesEnabled ? 'bg-green-500' : 'bg-red-500'
              }`}
            />
            <span className="text-gray-600">
              Charges: <strong>{stripeAccountDetails.chargesEnabled ? 'Enabled' : 'Disabled'}</strong>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <div
              className={`w-3 h-3 rounded-full ${
                stripeAccountDetails.payoutsEnabled ? 'bg-green-500' : 'bg-red-500'
              }`}
            />
            <span className="text-gray-600">
              Payouts: <strong>{stripeAccountDetails.payoutsEnabled ? 'Enabled' : 'Disabled'}</strong>
            </span>
          </div>
        </div>
      ) : (
        <p className="text-gray-500">Loading status...</p>
      )}
    </div>

    {/* Conditional Rendering of Buttons */}
    {stripeAccountDetails?.currentlyDue?.length > 0 ? (
      <div className="bg-yellow-50 p-4 rounded-lg shadow-sm">
        <h4 className="font-semibold text-lg text-yellow-800 mb-2">Required Actions</h4>
        <ul className="list-disc pl-5 text-yellow-700 mb-4">
          {stripeAccountDetails.currentlyDue.map((requirement, index) => (
            <li key={index}>{requirement.replace(/_/g, ' ').toLowerCase()}</li>
          ))}
        </ul>
        <button
          onClick={() => {
            setIsRedirecting(true);
            window.location.href = stripeAccountDetails.accountLink;
          }}
          disabled={isRedirecting}
          className={`block w-full text-center px-4 py-2 rounded-lg transition-colors duration-200 shadow ${
            isRedirecting
              ? 'bg-yellow-400 text-white cursor-not-allowed opacity-75'
              : 'bg-yellow-600 text-white hover:bg-yellow-700'
          }`}
        >
          {isRedirecting ? (
            <>
              <svg
                className="animate-spin h-5 w-5 inline-block mr-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Redirecting...
            </>
          ) : (
            'Complete Missing Information'
          )}
        </button>
      </div>
    ) : !stripeAccountDetails?.isComplete && (
      // Only show the Complete Setup button if the account is not complete
      <button
        onClick={handleStripeConnect}
        disabled={isConnectingStripe}
        className={`w-full mt-4 px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors duration-200 shadow-lg flex items-center justify-center ${
          isConnectingStripe ? 'opacity-75 cursor-not-allowed' : ''
        }`}
      >
        {isConnectingStripe ? (
          <>
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Connecting to Stripe...
          </>
        ) : (
          'Complete Setup'
        )}
      </button>
    )}
  </div>


              )}
            </div>
          </div>
        </>
      )}

      {/* Edit Account Modal */}
      {isEditAccountModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-xl shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-hidden relative">
            {/* Modal Header */}
            <div className="bg-gradient-to-r from-blue-500 to-blue-700 p-6">
              <h2 className="text-2xl font-bold text-white">Edit Account Details</h2>
              <button 
                onClick={closeEditAccountModal}
                className="absolute top-4 right-4 text-white hover:bg-blue-600 rounded-full p-2 transition-colors duration-200"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {/* Modal Content */}
            <div className="p-6 overflow-y-auto max-h-[calc(90vh-200px)]">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Company Name</label>
                    <input
                      type="text"
                      name="company_name"
                      value={accountData.company_name}
                      onChange={handleAccountChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
                    <input
                      type="text"
                      name="first_name"
                      value={accountData.first_name}
                      onChange={handleAccountChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
                    <input
                      type="text"
                      name="last_name"
                      value={accountData.last_name}
                      onChange={handleAccountChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                    <input
                      type="email"
                      name="contact_email"
                      value={accountData.contact_email}
                      onChange={handleAccountChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
                    <input
                      type="tel"
                      name="contact_phone"
                      value={accountData.contact_phone}
                      onChange={handleAccountChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Address</label>
                    <AddressAutocomplete
                      value={accountData.address}
                      onChange={(value) => setAccountData({ ...accountData, address: value })}
                      placeholder="Enter business address"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Mascot Name</label>
                    <input
                      type="text"
                      name="mascotname"
                      value={accountData.mascotname}
                      onChange={handleAccountChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Short Name</label>
                    <input
                      type="text"
                      name="short_name"
                      value={accountData.short_name}
                      onChange={handleAccountChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Modal Footer */}
            <div className="border-t px-6 py-4 bg-gray-50 flex justify-end space-x-4">
              <button
                onClick={closeEditAccountModal}
                className="px-6 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-100 transition-colors duration-200"
              >
                Cancel
              </button>
              <button
                onClick={saveAccountChanges}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Branding Modal */}
      {isEditBrandingModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-xl shadow-2xl w-full max-w-2xl max-h-[90vh] overflow-hidden relative">
            {/* Modal Header */}
            <div className="bg-gradient-to-r from-purple-500 to-purple-700 p-6">
              <h2 className="text-2xl font-bold text-white">Edit Branding</h2>
              <button 
                onClick={closeEditBrandingModal}
                className="absolute top-4 right-4 text-white hover:bg-purple-600 rounded-full p-2 transition-colors duration-200"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {/* Modal Content */}
            <div className="p-6 overflow-y-auto">
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Primary Color</label>
                  <div className="flex items-center space-x-4">
                    <input
                      type="color"
                      name="primary_color"
                      value={brandingData.primary_color}
                      onChange={handleBrandingChange}
                      className="w-20 h-20 rounded cursor-pointer"
                    />
                    <span className="text-gray-600">{brandingData.primary_color}</span>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Secondary Color</label>
                  <div className="flex items-center space-x-4">
                    <input
                      type="color"
                      name="secondary_color"
                      value={brandingData.secondary_color}
                      onChange={handleBrandingChange}
                      className="w-20 h-20 rounded cursor-pointer"
                    />
                    <span className="text-gray-600">{brandingData.secondary_color}</span>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Logo</label>
                  <div className="mt-2 space-y-4">
                    {/* Current Logo Preview */}
                    {brandingData.logoURL && (
                      <div className="flex items-center space-x-4">
                        <img src={brandingData.logoURL} alt="Current Logo" className="h-20 w-20 object-contain border rounded-lg" />
                        <span className="text-sm text-gray-500">Current Logo</span>
                      </div>
                    )}

                    {/* File Upload Section */}
                    <div className="flex flex-col space-y-2">
                      <div className="flex items-center space-x-4">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleLogoFileChange}
                          className="hidden"
                          id="logo-upload"
                        />
                        <label
                          htmlFor="logo-upload"
                          className="cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                          Choose New Logo
                        </label>
                        {isUploading && (
                          <div className="flex items-center text-purple-600">
                            <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                                fill="none"
                              />
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              />
                            </svg>
                            <span>Uploading...</span>
                          </div>
                        )}
                      </div>

                      {/* Selected File Info */}
                      {selectedFileName && !isUploading && (
                        <div className="flex items-center space-x-2 text-sm text-gray-600">
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                          </svg>
                          <span>{selectedFileName}</span>
                          <button
                            onClick={() => {
                              setSelectedFileName(null);
                              setLogoFile(null);
                            }}
                            className="text-red-500 hover:text-red-700"
                          >
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      )}

                      {/* Upload Progress or Status */}
                      {isUploading && (
                        <div className="w-full bg-gray-200 rounded-full h-2.5">
                          <div className="bg-purple-600 h-2.5 rounded-full w-full animate-pulse"></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal Footer */}
            <div className="border-t px-6 py-4 bg-gray-50 flex justify-end space-x-4">
              <button
                onClick={closeEditBrandingModal}
                className="px-6 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-100 transition-colors duration-200"
              >
                Cancel
              </button>
              <button
                onClick={saveBrandingChanges}
                disabled={isUploading}
                className={`px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors duration-200 ${
                  isUploading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isUploading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsTab;
