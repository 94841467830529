import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase, createVendorProfile } from '../supabaseClient';
import AddressAutocomplete from '../components/common/AddressAutocomplete';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function VendorSignup() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    type: 'school', // Default to school
    email: '',
    password: '',
    company_name: '',
    first_name: '',
    last_name: '',
    contact_phone: '',
    address: '',
    mascotname: '',
    short_name: ''
  });
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
      // Clear school-specific fields when switching from school to other types
      ...(name === 'type' && value !== 'school' ? { mascotname: '', short_name: '' } : {})
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setLoading(true);

    try {
      // Validate all required fields
      const requiredFields = {
        email: 'Email',
        password: 'Password',
        company_name: `${formData.type === 'school' ? 'School' : formData.type === 'business' ? 'Business' : 'Organization'} Name`,
        first_name: 'First Name',
        last_name: 'Last Name',
        contact_phone: 'Contact Phone',
        address: 'Address'
      };

      // Add school-specific fields if type is school
      if (formData.type === 'school') {
        requiredFields.mascotname = 'Mascot Name';
        requiredFields.short_name = 'Short Name';
      }

      // Check each required field
      for (const [field, label] of Object.entries(requiredFields)) {
        if (!formData[field]?.trim()) {
          setError(`${label} is required`);
          setLoading(false);
          return;
        }
      }

      // Check if email is already registered as a customer
      const { data: existingUser, error: userCheckError } = await supabase
        .from('users')
        .select('role')
        .eq('email', formData.email)
        .single();

      if (!userCheckError && existingUser) {
        setError('This email is already registered. Please use a different email address.');
        setLoading(false);
        return;
      }

      // Sign up the user
      const { data, error: signUpError } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password
      });

      if (signUpError) throw signUpError;

      // Create vendor profile with only the relevant fields
      const profileData = {
        ...formData,
        // Only include school-specific fields if type is school
        ...(formData.type !== 'school' && {
          mascotname: null,
          short_name: null
        })
      };

      const { error: profileError } = await createVendorProfile(data.user.id, profileData);
      
      if (profileError) throw profileError;

      setMessage('Signup successful! Please check your email for verification.');
      setTimeout(() => {
        navigate('/vendor-login');
      }, 2000);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-6">
          <img
            src="/images/Logo.png"
            alt="Hometown"
            className="w-32 object-contain"
          />
        </div>
        <h2 className="text-center text-blue-800 font-bold text-xl mb-4">
          Valley Tickets
        </h2>
        <p className="text-center text-gray-600 mb-6">
          Create your vendor account to start selling tickets.
        </p>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        {message && <p className="text-green-500 text-center mb-4">{message}</p>}

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Business Type Selection - First Field */}
          <div className="mb-4">
            <select
              name="type"
              value={formData.type}
              onChange={handleChange}
              className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
              required
            >
              <option value="school">School</option>
              <option value="business">Business</option>
              <option value="organization">Organization</option>
            </select>
          </div>

          <div className="mb-4">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
              required
            />
          </div>

          <div className="mb-4 relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
            >
              {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
            </button>
          </div>

          <div className="mb-4">
            <input
              type="text"
              name="company_name"
              placeholder={`${formData.type === 'school' ? 'School' : formData.type === 'business' ? 'Business' : 'Organization'} Name`}
              value={formData.company_name}
              onChange={handleChange}
              className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
              required
            />
          </div>

          {/* School-specific fields */}
          {formData.type === 'school' && (
            <>
              <div className="mb-4">
                <input
                  type="text"
                  name="short_name"
                  placeholder="Short Name (e.g., Veterans Memorial → Vets)"
                  value={formData.short_name}
                  onChange={handleChange}
                  className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
                  required={formData.type === 'school'}
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="mascotname"
                  placeholder="Mascot Name"
                  value={formData.mascotname}
                  onChange={handleChange}
                  className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
                  required={formData.type === 'school'}
                />
              </div>
            </>
          )}

          <div className="grid grid-cols-2 gap-4 mb-4">
            <input
              type="text"
              name="first_name"
              placeholder="First Name"
              value={formData.first_name}
              onChange={handleChange}
              className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
              required
            />

            <input
              type="text"
              name="last_name"
              placeholder="Last Name"
              value={formData.last_name}
              onChange={handleChange}
              className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
              required
            />
          </div>

          <div className="mb-4">
            <input
              type="tel"
              name="contact_phone"
              placeholder="Contact Phone"
              value={formData.contact_phone}
              onChange={handleChange}
              className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
              required
            />
          </div>

          <div className="mb-4">
            <AddressAutocomplete
              value={formData.address}
              onChange={(value) => setFormData({ ...formData, address: value })}
              placeholder="Enter business address"
            />
          </div>

          <button
            type="submit"
            className={`w-full bg-gray-800 text-white py-3 rounded-lg text-lg font-semibold hover:bg-gray-700 transition duration-300 ${loading && 'opacity-50'}`}
            disabled={loading}
          >
            {loading ? 'Signing up...' : 'Sign Up'}
          </button>
        </form>

        <div className="text-center mt-6">
          <p className="text-gray-600">
            Already have a vendor account?{' '}
            <Link to="/vendor-login" className="text-blue-600 underline">
              Login here
            </Link>
          </p>
          <p className="text-gray-600 mt-2">
            Are you a customer?{' '}
            <Link to="/login" className="text-blue-600 underline">
              Click here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default VendorSignup; 