import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { useAuth } from '../AuthContext';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function VendorLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isResetMode, setIsResetMode] = useState(false);
  const navigate = useNavigate();
  const { checkAuthStatus } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setLoading(true);

    try {
      // First check if the email exists in users table and what role they have
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('role')
        .eq('email', email)
        .single();

      if (!userError && userData && userData.role !== 'vendor') {
        setError('This email is registered as a customer account. Please use the customer login page.');
        setLoading(false);
        return;
      }

      // Proceed with login only if the user wasn't found or is a vendor
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      if (error) throw error;

      // Double check the role after login (security measure)
      const { data: userDataAfterLogin, error: userErrorAfterLogin } = await supabase
        .from('users')
        .select('role')
        .eq('id', data.user.id)
        .single();

      if (userErrorAfterLogin) throw userErrorAfterLogin;

      if (userDataAfterLogin.role !== 'vendor') {
        // Force logout if somehow a non-vendor got through
        await supabase.auth.signOut();
        throw new Error('Access denied. This login is for vendors only.');
      }

      await checkAuthStatus();
      setMessage('Login successful!');
      navigate('/vendor-dashboard');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setLoading(true);

    try {
      // First check if the email exists in users table and what role they have
      const { data: users, error: userError } = await supabase
        .from('users')
        .select('role')
        .eq('email', email);

      if (userError) {
        throw userError;
      }

      // If user exists and is not a vendor, show error
      if (users && users.length > 0 && users[0].role !== 'vendor') {
        setError('This email is registered as a customer account. Password reset is only available for vendor accounts.');
        return;
      }

      // If we get here, either the user doesn't exist or they are a vendor
      const { error } = await supabase.auth.resetPasswordForEmail(
        email,
        {
          redirectTo: `${window.location.origin}/reset-password`,
          data: {
            role: 'vendor'
          }
        }
      );

      if (error) throw error;

      setMessage('If an account exists with this email, you will receive password reset instructions shortly.');
      setIsResetMode(false);
    } catch (error) {
      console.error('Reset error:', error);
      setError('Failed to send reset instructions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-6">
          <img
            src="/images/Logo.png"
            alt="Hometown"
            className="w-32 object-contain"
          />
        </div>
        <h2 className="text-center text-blue-800 font-bold text-xl mb-4">
          Valley Tickets
        </h2>
        <p className="text-center text-gray-600 mb-6">
          {isResetMode 
            ? "Enter your email to receive password reset instructions"
            : "Please enter your vendor account credentials to login."
          }
        </p>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        {message && <p className="text-green-500 text-center mb-4">{message}</p>}

        <form onSubmit={isResetMode ? handlePasswordReset : handleLogin} className="space-y-4">
          <div className="mb-4">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
              required
            />
          </div>

          {!isResetMode && (
            <div className="mb-4 relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
              >
                {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
              </button>
            </div>
          )}

          <button
            type="submit"
            className={`w-full bg-gray-800 text-white py-3 rounded-lg text-lg font-semibold hover:bg-gray-700 transition duration-300 ${loading && 'opacity-50'}`}
            disabled={loading}
          >
            {loading 
              ? (isResetMode ? 'Sending...' : 'Logging in...') 
              : (isResetMode ? 'Send Reset Instructions' : 'Login')
            }
          </button>
        </form>

        <div className="text-center mt-6">
          <button
            onClick={() => {
              setIsResetMode(!isResetMode);
              setError(null);
              setMessage(null);
            }}
            className="text-blue-600 hover:text-blue-800 underline mb-4"
          >
            {isResetMode ? 'Back to Login' : 'Forgot Password?'}
          </button>

          <p className="text-gray-600 mt-4">
            Need a vendor account?{' '}
            <Link to="/vendor-signup" className="text-blue-600 underline">
              Sign up here
            </Link>
          </p>
          <p className="text-gray-600 mt-2">
            Are you a customer?{' '}
            <Link to="/login" className="text-blue-600 underline">
              Click here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default VendorLogin;