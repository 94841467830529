import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { supabase } from '../supabaseClient';

const Layout = ({ children, vendor_color, vendorData }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const closeMenu = () => setIsMenuOpen(false);

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    window.location.href = '/';
  };

  const NavItem = ({ to, children }) => {
    const isActive = location.pathname === to;
    return (
      <Link
        to={to}
        className={`cursor-pointer ${
          isActive ? 'font-bold text-yellow-300' : 'text-white'
        }`}
        onClick={closeMenu}
      >
        {children}
      </Link>
    );
  };

  const AuthNavItem = () => {
    if (isAuthenticated === null) {
      return <div className="text-white">Loading...</div>;
    }

    return isAuthenticated && isAuthenticated.authenticated ? (
      <>
        {isAuthenticated.role === 'vendor' ? (
          <NavItem to="/vendor-dashboard">Dashboard</NavItem>
        ) : (
          <NavItem to="/account">Account</NavItem>
        )}
        <button
          onClick={handleSignOut}
          className="text-white px-4 py-2 ml-4 bg-red-600 rounded hover:bg-red-700"
        >
          Sign Out
        </button>
      </>
    ) : (
      <NavItem to="/login">Login/Signup</NavItem>
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-blue-700">
      <header
        className="w-full p-6"
        style={{ backgroundColor: vendorData && vendorData.primary_color || '#1e3a8a' }}
      >
        <div className="max-w-6xl mx-auto flex items-center justify-between">
        <Link to="/" className="flex items-center">
            <img 
              src="/images/updated_logo_navbar.png" 
              alt="Valley Tickets Logo" 
              className="h-8 md:h-12" 
            />
          </Link>

          <button
            className="text-white block md:hidden"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>

          <div className="hidden md:flex items-center space-x-8 text-white">
            <NavItem to="/">Search</NavItem>
            <NavItem to="/tickets">My Tickets</NavItem>
            <NavItem to="/passes">My Passes</NavItem>
            <AuthNavItem />
          </div>
        </div>
      </header>

      {isMenuOpen && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={closeMenu}
          ></div>

          <div
            className="fixed inset-0 z-50 text-white p-6"
            style={{ backgroundColor: vendor_color || '#1e3a8a' }}
          >
            <button
              className="absolute top-4 right-4 text-white"
              onClick={closeMenu}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>

            <nav className="flex flex-col space-y-4 mt-8">
              <NavItem to="/">Search</NavItem>
              <NavItem to="/tickets">My Tickets</NavItem>
              <NavItem to="/passes">My Passes</NavItem>
              <AuthNavItem />
            </nav>
          </div>
        </>
      )}
      <div className='bg-white w-full py-1 px-6 '></div>

      <main className="flex-grow bg-blue-700">
        <div className="w-full max-w-6xl mx-auto h-full">   
          {vendorData && (
            <section
              className="relative text-white p-8"
              style={{ backgroundColor: vendorData.secondary_color }}
            >
              <div className="container mx-auto flex items-center">
                <div
                  className="pointer logo bg-cover bg-center mr-4"
                  style={{
                    backgroundImage: `url(${vendorData.logoURL})`,
                    width: '60px',
                    height: '60px',
                    backgroundColor: 'white',
                  }}
                ></div>
                <div>
                  <span className="text-uppercase text-white font-bold" style={{ fontSize: '1.2rem' }}>
                    {vendorData.company_name}
                  </span>
                  <span className="text-white ml-2">
                    <span className="d-none d-lg-inline">• </span>
                    <span className="d-inline-block d-lg-none"><br /></span>{vendorData.address}
                  </span>
                </div>
              </div>
            </section>
          )}
          {children}
        </div>
      </main>
    </div>
  );
};

export default Layout;
