// DashboardTab.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';

const DashboardTab = () => {
  const [stats, setStats] = useState({
    activeEvents: 0,
    upcomingEvents: 0,
    pastEvents: 0,
    totalTicketsSold: 0,
    loading: true,
    error: null
  });

  useEffect(() => {
    const fetchEventStats = async () => {
      try {
        // Get current vendor's ID
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No user found');

        // Get vendor ID from users table
        const { data: vendorData, error: vendorError } = await supabase
          .from('vendors')
          .select('id')
          .eq('user_id', user.id)
          .single();

        if (vendorError) throw vendorError;

        const currentDate = new Date().toISOString();

        // Get all non-deleted events
        const { data: allEvents, error: activeError } = await supabase
          .from('events')
          .select('id, start_date')
          .eq('vendor_id', vendorData.id)
          .eq('isDeleted', false);

        if (activeError) throw activeError;

        // Split events into upcoming and past
        const upcoming = allEvents?.filter(event => new Date(event.start_date) >= new Date()) || [];
        const past = allEvents?.filter(event => new Date(event.start_date) < new Date()) || [];

        // Get total tickets sold
        const { count: totalTickets, error: ticketsError } = await supabase
          .from('tickets_sold')
          .select('*', { count: 'exact' })
          .eq('vendor_id', vendorData.id);

        if (ticketsError) throw ticketsError;

        setStats({
          activeEvents: upcoming.length,
          upcomingEvents: upcoming.length,
          pastEvents: past.length,
          totalTicketsSold: totalTickets || 0,
          loading: false,
          error: null
        });

      } catch (error) {
        console.error('Error fetching event stats:', error);
        setStats(prev => ({
          ...prev,
          loading: false,
          error: 'Failed to load event statistics'
        }));
      }
    };

    fetchEventStats();
  }, []);

  if (stats.loading) {
    return (
      <div className="p-6">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-48 mb-6"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="h-4 bg-gray-200 rounded w-24 mb-3"></div>
              <div className="h-8 bg-gray-200 rounded w-16 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-20"></div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="h-4 bg-gray-200 rounded w-24 mb-3"></div>
              <div className="h-8 bg-gray-200 rounded w-16 mb-2"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stats.error) {
    return (
      <div className="p-6">
        <div className="text-red-500">
          {stats.error}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Dashboard Overview</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-gray-500 text-sm font-semibold">Upcoming Events</h3>
          <p className="text-3xl font-bold">{stats.upcomingEvents}</p>
          <p className="text-blue-500 text-sm">Active events</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-gray-500 text-sm font-semibold">Past Events</h3>
          <p className="text-3xl font-bold">{stats.pastEvents}</p>
          <p className="text-blue-500 text-sm">Completed events</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-gray-500 text-sm font-semibold">Total Tickets Sold</h3>
          <p className="text-3xl font-bold">{stats.totalTicketsSold}</p>
          <p className="text-blue-500 text-sm">All time</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardTab;
