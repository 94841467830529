import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { FiEdit3, FiCheck, FiX } from 'react-icons/fi';

const AccountPage = () => {
  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  });

  const [editField, setEditField] = useState({
    first_name: false,
    last_name: false,
    email: false,
    phone_number: false,
  });

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('id', user.id)
          .single();
          
        if (error) throw error;
        if (data) {
          setUserInfo({
            first_name: data.first_name || '',
            last_name: data.last_name || '',
            email: data.email || '',
            phone_number: data.phone_number || '',
          });
        }
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSave = async (field) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      const { error } = await supabase
        .from('users')
        .update({ [field]: userInfo[field] })
        .eq('id', user.id);

      if (error) throw error;

      setEditField({ ...editField, [field]: false });
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleEdit = (field) => {
    setEditField({ ...editField, [field]: true });
  };

  const handleCancel = (field) => {
    setEditField({ ...editField, [field]: false });
  };

  const renderField = (label, field, type = "text") => (
    <div className="mb-6">
      <label className="block">
        <span className="text-gray-700 font-medium">{label}</span>
        <div className="relative mt-1 flex items-center">
          <input
            type={type}
            name={field}
            value={userInfo[field]}
            onChange={handleInputChange}
            disabled={field === 'email' || !editField[field]}
            className={`w-full px-4 py-2 rounded-lg border focus:outline-none transition-all duration-300 ${
              field === 'email' 
                ? 'border-gray-300 bg-gray-100 text-gray-600' 
                : editField[field]
                  ? 'border-blue-500 focus:border-blue-600'
                  : 'border-gray-300 bg-gray-100 text-gray-600'
            }`}
          />
          {!userInfo[field] && (
            <span className="absolute right-3 text-red-500 text-xs font-semibold">
              Missing Info
            </span>
          )}
        </div>
      </label>
      {field !== 'email' && (
        <div className="flex justify-end mt-2 space-x-2">
          {editField[field] ? (
            <>
              <button
                onClick={() => handleSave(field)}
                className="flex items-center space-x-1 bg-blue-600 text-white font-semibold rounded-lg py-1 px-3 hover:bg-blue-700 transition-all duration-200"
              >
                <FiCheck className="w-4 h-4" />
                <span>Save</span>
              </button>
              <button
                onClick={() => handleCancel(field)}
                className="flex items-center space-x-1 bg-gray-300 text-gray-700 font-semibold rounded-lg py-1 px-3 hover:bg-gray-400 transition-all duration-200"
              >
                <FiX className="w-4 h-4" />
                <span>Cancel</span>
              </button>
            </>
          ) : (
            <button
              onClick={() => handleEdit(field)}
              className="flex items-center space-x-1 bg-blue-500 text-white font-semibold rounded-lg py-1 px-3 hover:bg-blue-600 transition-all duration-200"
            >
              <FiEdit3 className="w-4 h-4" />
              <span>Edit</span>
            </button>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <main className="w-full max-w-lg bg-white rounded-2xl shadow-lg p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">Account Information</h1>
        {renderField("First Name", "first_name")}
        {renderField("Last Name", "last_name")}
        {renderField("Email", "email", "email")}
        {renderField("Phone Number", "phone_number", "tel")}
      </main>
    </div>
  );
};

export default AccountPage;
