import React from 'react';
import { Link } from 'react-router-dom';
import { FaExclamationTriangle, FaHome } from 'react-icons/fa';

const NotFound = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
        <div className="flex justify-center mb-6">
          <img
            src="/images/Logo.png"
            alt="Valley Tickets"
            className="w-32 object-contain"
          />
        </div>
        
        <FaExclamationTriangle className="mx-auto text-6xl text-yellow-500 mb-4" />
        
        <h1 className="text-3xl font-bold text-gray-800 mb-2">
          Page Not Found
        </h1>
        
        <p className="text-gray-600 mb-8">
          The page you're looking for doesn't exist or has been removed.
        </p>

        <Link 
          to="/"
          className="inline-flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
        >
          <FaHome className="mr-2" />
          Return Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound; 