import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchVendorById, fetchEventsByVendorId } from '../supabaseClient';
import { FaTh, FaList, FaCalendarAlt, FaHistory } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import Layout from '../components/Layout';

const SPORTS_EVENTS = [
  'Basketball',
  'Football',
  'Soccer',
  'Baseball',
  'Softball',
  'Volleyball',
  'Track & Field',
  'Wrestling',
  'Tennis',
  'Golf',
  'Swimming',
  'Cross Country',
];

const defaultSportsImageUrl = "https://s3.amazonaws.com/com.ticketspicket.app/activity-images/basketball_Edited.jpg";
const defaultEventImageUrl = "https://www.pncfairfaxconnection.com/wp-content/themes/pnc-child/img/event_placeholder.jpg";

const isPastEvent = (date) => {
  return new Date(date) < new Date();
};

function VendorPage() {
  const [vendor, setVendor] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isListView, setIsListView] = useState(false);
  const [activeTab, setActiveTab] = useState('upcoming');
  const { id } = useParams();

  useEffect(() => {
    const loadVendorData = async () => {
      setLoading(true);

      const { data: vendorData, error: vendorError } = await fetchVendorById(id);
      if (vendorError) {
        setError(vendorError.message);
        setLoading(false);
        return;
      }
      setVendor(vendorData);

      const { data: eventsData, error: eventsError } = await fetchEventsByVendorId(id);
      if (eventsError) {
        setError(eventsError.message);
      } else {
        const currentDate = new Date();
        const filteredEvents = eventsData.filter(event => {
          const eventDate = new Date(event.start_date);
          return activeTab === 'upcoming' 
            ? eventDate >= currentDate 
            : eventDate < currentDate;
        });

        filteredEvents.sort((a, b) => {
          const dateA = new Date(a.start_date);
          const dateB = new Date(b.start_date);
          return activeTab === 'upcoming' 
            ? dateA - dateB  // Upcoming: soonest first
            : dateB - dateA; // Past: most recent first
        });

        setEvents(filteredEvents);
      }
      
      setLoading(false);
    };

    loadVendorData();
  }, [id, activeTab]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!vendor) return <p>Vendor not found</p>;

  return (
    <Layout vendorData={vendor}> 
      <div className="min-h-screen bg-gray-100">
        
        {/* Secondary Banner */}
        <section
          className="text-center text-white py-28"
          style={{
            background: `
              repeating-radial-gradient(circle at 10px 10px, ${vendor.primary_color}, ${vendor.primary_color} 10px, ${vendor.secondary_color} 10px, ${vendor.secondary_color} 20px)
            `,
          }}
        >
          <h3 className="text-4xl font-bold">{vendor.company_name}</h3>
          <h1 className="text-2xl font-semibold">{vendor.mascotname}</h1>
        </section>

        {/* Events Section */}
        <main className="flex justify-center items-center py-8">
          <div className="w-full max-w-6xl bg-white p-6">
            <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-6">
              <h2 className="text-3xl font-bold mb-4 sm:mb-0">EVENTS</h2>
              <div className="flex bg-gray-100 rounded-lg p-1 self-start">
                <button
                  onClick={() => setActiveTab('upcoming')}
                  className={`px-3 py-2 md:px-4 text-sm md:text-base rounded-md transition-colors duration-200 ${
                    activeTab === 'upcoming'
                      ? 'bg-white text-blue-600 shadow-sm'
                      : 'text-gray-600 hover:text-gray-800'
                  }`}
                >
                  Upcoming Events
                </button>
                <button
                  onClick={() => setActiveTab('past')}
                  className={`px-3 py-2 md:px-4 text-sm md:text-base rounded-md transition-colors duration-200 ${
                    activeTab === 'past'
                      ? 'bg-white text-blue-600 shadow-sm'
                      : 'text-gray-600 hover:text-gray-800'
                  }`}
                >
                  Past Events
                </button>
              </div>
            </div>

            {events.length === 0 && (
              <div className="text-center py-12">
                <div className="bg-gray-100 rounded-full p-4 w-16 h-16 mx-auto mb-4 flex items-center justify-center">
                  {activeTab === 'upcoming' ? (
                    <FaCalendarAlt className="w-8 h-8 text-gray-400" />
                  ) : (
                    <FaHistory className="w-8 h-8 text-gray-400" />
                  )}
                </div>
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  {activeTab === 'upcoming' ? 'No Upcoming Events' : 'No Past Events'}
                </h3>
                <p className="text-gray-600">
                  {activeTab === 'upcoming' 
                    ? 'Check back later for new events.'
                    : 'Past events will appear here once events are completed.'
                  }
                </p>
              </div>
            )}

            {events.length > 0 && (
              <>
                <div className="flex justify-end mb-4">
                  <button
                    className={`p-2 ${!isListView ? 'text-white' : 'text-gray-600'}`}
                    style={{ backgroundColor: !isListView ? vendor.primary_color : 'transparent' }}
                    onClick={() => setIsListView(false)}
                  >
                    <FaTh />
                  </button>
                  <button
                    className={`p-2 ${isListView ? 'text-white' : 'text-gray-600'}`}
                    style={{ backgroundColor: isListView ? vendor.primary_color : 'transparent' }}
                    onClick={() => setIsListView(true)}
                  >
                    <FaList />
                  </button>
                </div>

                <div className={`grid ${isListView ? 'grid-cols-1' : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3'} gap-6`}>
                  {events.map((event) => (
                    <div key={event.id}>
                      {isPastEvent(event.start_date) ? (
                        <div className="block">
                          <div
                            className={`bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 ${
                              isListView ? 'p-4 flex flex-col justify-between' : 'relative'
                            }`}
                          >
                            {isListView ? (
                              <>
                                {/* Logo and Event Details */}
                                <div className="flex items-start">
                                  <img
                                    src={vendor.logoURL}
                                    alt="Vendor Logo"
                                    className="w-12 h-12 rounded-full bg-white p-1 mr-4 flex-shrink-0"
                                  />
                                  <div className="flex-grow min-w-0">
                                    <h3 className="text-lg font-bold truncate">
                                      {SPORTS_EVENTS.includes(event.event_type) 
                                        ? `${vendor.short_name} vs ${event.opponent}`
                                        : event.title
                                      }
                                    </h3>
                                    <p className="text-gray-600 mb-2">
                                      {SPORTS_EVENTS.includes(event.event_type)
                                        ? `${event.gender_type} ${event.event_type}`
                                        : event.event_type
                                      }
                                    </p>
                                    <div className="flex items-center text-gray-500 text-sm">
                                      <MdLocationOn className="mr-1 flex-shrink-0" />
                                      <span className="truncate">{event.location}</span>
                                    </div>
                                  </div>
                                  {/* Date Box */}
                                  <div
                                    className="text-white p-2 text-center rounded-lg ml-auto"
                                    style={{ backgroundColor: vendor.primary_color || '#0000FF' }}
                                  >
                                    <span className="block text-sm font-bold">
                                      {new Date(event.start_date).toLocaleString('en-US', { 
                                        weekday: 'short',
                                        hour12: true,
                                        timeZone: 'UTC'
                                      })}
                                    </span>
                                    <span className="block text-xl font-bold">
                                      {new Date(event.start_date).toLocaleString('en-US', { 
                                        day: '2-digit', 
                                        month: 'short',
                                        timeZone: 'UTC'
                                      })}
                                    </span>
                                    <span className="block text-sm font-semibold mt-1">
                                      {new Date(event.start_date).toLocaleString('en-US', { 
                                        hour: '2-digit', 
                                        minute: '2-digit',
                                        hour12: true,
                                        timeZone: 'UTC'
                                      })}
                                    </span>
                                  </div>
                                </div>
                                {/* Buy Tickets Section with Border */}
                                <div className="border-t pt-4 mt-4 flex justify-between items-center">
                                  <div className="text-gray-500 text-2xl">
                                    <i className="fas fa-basketball-ball"></i>
                                  </div>
                                  <span className={`font-semibold ${
                                    isPastEvent(event.start_date)
                                      ? 'text-gray-400 cursor-not-allowed'
                                      : 'text-blue-600 hover:underline'
                                  }`}>
                                    {isPastEvent(event.start_date) ? 'Event Ended' : 'Buy Tickets →'}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                {/* Image Section with Default Image Fallback */}
                                <div className="relative">
                                  <img
                                    src={event.image_url || 
                                      (SPORTS_EVENTS.includes(event.event_type) ? defaultSportsImageUrl : defaultEventImageUrl)
                                    }
                                    alt={event.title}
                                    className="w-full h-48 object-cover"
                                  />
                                  <img
                                    src={vendor.logoURL}
                                    alt="Vendor Logo"
                                    className="absolute top-2 left-2 w-10 h-10 rounded-full bg-white p-1"
                                  />
                                  <div
                                    className="absolute top-2 right-2 text-white p-2 text-center rounded-lg"
                                    style={{ backgroundColor: vendor.primary_color || '#0000FF' }}
                                  >
                                    <span className="block text-sm font-bold">
                                      {new Date(event.start_date).toLocaleString('en-US', { 
                                        weekday: 'short',
                                        hour12: true,
                                        timeZone: 'UTC'
                                      })}
                                    </span>
                                    <span className="block text-xl font-bold">
                                      {new Date(event.start_date).toLocaleString('en-US', { 
                                        day: '2-digit', 
                                        month: 'short',
                                        timeZone: 'UTC'
                                      })}
                                    </span>
                                    <span className="block text-sm font-semibold mt-1">
                                      {new Date(event.start_date).toLocaleString('en-US', { 
                                        hour: '2-digit', 
                                        minute: '2-digit',
                                        hour12: true,
                                        timeZone: 'UTC'
                                      })}
                                    </span>
                                  </div>
                                </div>
                                {/* Content Section */}
                                <div className="p-4">
                                  <h3 className="text-lg font-bold truncate">
                                    {SPORTS_EVENTS.includes(event.event_type) 
                                      ? `${vendor.short_name} vs ${event.opponent}`
                                      : event.title
                                    }
                                  </h3>
                                  <p className="text-gray-600 mb-2">
                                    {SPORTS_EVENTS.includes(event.event_type)
                                      ? `${event.gender_type} ${event.event_type}`
                                      : event.event_type
                                    }
                                  </p>
                                  <div className="flex items-center text-gray-500 text-sm mb-4">
                                    <MdLocationOn className="mr-1 flex-shrink-0" />
                                    <span className="truncate">{event.location}</span>
                                  </div>
                                  <div className="border-t pt-4 mt-auto flex justify-between items-center">
                                    <div className="text-gray-500 text-2xl">
                                      <i className="fas fa-basketball-ball"></i>
                                    </div>
                                    <span className={`font-semibold ${
                                      isPastEvent(event.start_date)
                                        ? 'text-gray-400 cursor-not-allowed'
                                        : 'text-blue-600 hover:underline'
                                    }`}>
                                      {isPastEvent(event.start_date) ? 'Event Ended' : 'Buy Tickets'}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <Link to={`/event/${event.id}`} className="block">
                          <div
                            className={`bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 ${
                              isListView ? 'p-4 flex flex-col justify-between' : 'relative'
                            }`}
                          >
                            {isListView ? (
                              <>
                                {/* Logo and Event Details */}
                                <div className="flex items-start">
                                  <img
                                    src={vendor.logoURL}
                                    alt="Vendor Logo"
                                    className="w-12 h-12 rounded-full bg-white p-1 mr-4 flex-shrink-0"
                                  />
                                  <div className="flex-grow min-w-0">
                                    <h3 className="text-lg font-bold truncate">
                                      {SPORTS_EVENTS.includes(event.event_type) 
                                        ? `${vendor.short_name} vs ${event.opponent}`
                                        : event.title
                                      }
                                    </h3>
                                    <p className="text-gray-600 mb-2">
                                      {SPORTS_EVENTS.includes(event.event_type)
                                        ? `${event.gender_type} ${event.event_type}`
                                        : event.event_type
                                      }
                                    </p>
                                    <div className="flex items-center text-gray-500 text-sm">
                                      <MdLocationOn className="mr-1 flex-shrink-0" />
                                      <span className="truncate">{event.location}</span>
                                    </div>
                                  </div>
                                  {/* Date Box */}
                                  <div
                                    className="text-white p-2 text-center rounded-lg ml-auto"
                                    style={{ backgroundColor: vendor.primary_color || '#0000FF' }}
                                  >
                                    <span className="block text-sm font-bold">
                                      {new Date(event.start_date).toLocaleString('en-US', { 
                                        weekday: 'short',
                                        hour12: true,
                                        timeZone: 'UTC'
                                      })}
                                    </span>
                                    <span className="block text-xl font-bold">
                                      {new Date(event.start_date).toLocaleString('en-US', { 
                                        day: '2-digit', 
                                        month: 'short',
                                        timeZone: 'UTC'
                                      })}
                                    </span>
                                    <span className="block text-sm font-semibold mt-1">
                                      {new Date(event.start_date).toLocaleString('en-US', { 
                                        hour: '2-digit', 
                                        minute: '2-digit',
                                        hour12: true,
                                        timeZone: 'UTC'
                                      })}
                                    </span>
                                  </div>
                                </div>
                                {/* Buy Tickets Section with Border */}
                                <div className="border-t pt-4 mt-4 flex justify-between items-center">
                                  <div className="text-gray-500 text-2xl">
                                    <i className="fas fa-basketball-ball"></i>
                                  </div>
                                  <span className={`font-semibold ${
                                    isPastEvent(event.start_date)
                                      ? 'text-gray-400 cursor-not-allowed'
                                      : 'text-blue-600 hover:underline'
                                  }`}>
                                    {isPastEvent(event.start_date) ? 'Event Ended' : 'Buy Tickets →'}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                {/* Image Section with Default Image Fallback */}
                                <div className="relative">
                                  <img
                                    src={event.image_url || 
                                      (SPORTS_EVENTS.includes(event.event_type) ? defaultSportsImageUrl : defaultEventImageUrl)
                                    }
                                    alt={event.title}
                                    className="w-full h-48 object-cover"
                                  />
                                  <img
                                    src={vendor.logoURL}
                                    alt="Vendor Logo"
                                    className="absolute top-2 left-2 w-10 h-10 rounded-full bg-white p-1"
                                  />
                                  <div
                                    className="absolute top-2 right-2 text-white p-2 text-center rounded-lg"
                                    style={{ backgroundColor: vendor.primary_color || '#0000FF' }}
                                  >
                                    <span className="block text-sm font-bold">
                                      {new Date(event.start_date).toLocaleString('en-US', { 
                                        weekday: 'short',
                                        hour12: true,
                                        timeZone: 'UTC'
                                      })}
                                    </span>
                                    <span className="block text-xl font-bold">
                                      {new Date(event.start_date).toLocaleString('en-US', { 
                                        day: '2-digit', 
                                        month: 'short',
                                        timeZone: 'UTC'
                                      })}
                                    </span>
                                    <span className="block text-sm font-semibold mt-1">
                                      {new Date(event.start_date).toLocaleString('en-US', { 
                                        hour: '2-digit', 
                                        minute: '2-digit',
                                        hour12: true,
                                        timeZone: 'UTC'
                                      })}
                                    </span>
                                  </div>
                                </div>
                                {/* Content Section */}
                                <div className="p-4">
                                  <h3 className="text-lg font-bold truncate">
                                    {SPORTS_EVENTS.includes(event.event_type) 
                                      ? `${vendor.short_name} vs ${event.opponent}`
                                      : event.title
                                    }
                                  </h3>
                                  <p className="text-gray-600 mb-2">
                                    {SPORTS_EVENTS.includes(event.event_type)
                                      ? `${event.gender_type} ${event.event_type}`
                                      : event.event_type
                                    }
                                  </p>
                                  <div className="flex items-center text-gray-500 text-sm mb-4">
                                    <MdLocationOn className="mr-1 flex-shrink-0" />
                                    <span className="truncate">{event.location}</span>
                                  </div>
                                  <div className="border-t pt-4 mt-auto flex justify-between items-center">
                                    <div className="text-gray-500 text-2xl">
                                      <i className="fas fa-basketball-ball"></i>
                                    </div>
                                    <span className={`font-semibold ${
                                      isPastEvent(event.start_date)
                                        ? 'text-gray-400 cursor-not-allowed'
                                        : 'text-blue-600 hover:underline'
                                    }`}>
                                      {isPastEvent(event.start_date) ? 'Event Ended' : 'Buy Tickets'}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </main>
      </div>
    </Layout>
  );
}

export default VendorPage;
