import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase, createUserProfile } from '../supabaseClient';
import { useAuth } from '../AuthContext';

function Login() {
  const [identifier, setIdentifier] = useState(''); // Email or phone
  const [otp, setOtp] = useState(''); // OTP input
  const [usePhone, setUsePhone] = useState(false); // Toggle between email and phone
  const [error, setError] = useState(null); // Error message state
  const [message, setMessage] = useState(null); // Success message state
  const [showOtpInput, setShowOtpInput] = useState(false); // Toggle for showing OTP input
  const [isLoading, setIsLoading] = useState(false); // Loading state for disabling buttons
  const [isCheckingAuth, setIsCheckingAuth] = useState(true); // Loading state for auth check
  const { setIsAuthenticated, checkAuthStatus, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      await checkAuthStatus();
      setIsCheckingAuth(false);
    };
    checkAuthentication();
  }, [checkAuthStatus]);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setIsLoading(true);
  
    // Format the identifier if it's a phone number
    const formattedIdentifier = usePhone && !identifier.startsWith("+1")
      ? `+1${identifier.replace(/\D/g, '')}` // Adds +1 and removes non-numeric characters
      : identifier;
  
    const { error } = await supabase.auth.signInWithOtp({
      [usePhone ? 'phone' : 'email']: formattedIdentifier
    });
  
    setIsLoading(false);
    if (error) {
      setError(error.message);
    } else {
      setMessage(`OTP sent to your ${usePhone ? 'phone' : 'email'}. Please check and enter the code.`);
      setShowOtpInput(true);
    }
  };
  

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setIsLoading(true);

    try {
      const { data, error } = await supabase.auth.verifyOtp({
        [usePhone ? 'phone' : 'email']: identifier,
        token: otp,
        type: usePhone ? 'sms' : 'email'
      });

      if (error) throw error;

      // Get the user ID from the session
      const userId = data.user.id;

      // Create user profile if it doesn't exist
      const { error: profileError } = await createUserProfile(userId, {
        [usePhone ? 'phone' : 'email']: identifier
      });

      if (profileError) throw profileError;

      await checkAuthStatus();
      setMessage('Login successful!');
      navigate('/');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && isAuthenticated.authenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  if (isCheckingAuth) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-6">
          <img
            src="/images/Logo.png"
            alt="Hometown"
            className="w-32 object-contain"
          />
        </div>
        <h2 className="text-center text-blue-800 font-bold text-xl mb-4">
          VALLEY TICKETS
        </h2>
        <p className="text-center text-gray-600 mb-6">
          {showOtpInput 
            ? "Please enter the OTP you received." 
            : `Please enter your ${usePhone ? 'phone number' : 'email'} to receive an OTP.`
          }
        </p>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        {message && <p className="text-green-500 text-center mb-4">{message}</p>}
        <form onSubmit={showOtpInput ? handleVerifyOtp : handleSendOtp}>
          {!showOtpInput && (
            <div className="mb-4">
              <input
                type={usePhone ? 'tel' : 'email'}
                placeholder={usePhone ? 'Phone Number' : 'Email'}
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
                required
              />
            </div>
          )}
          {showOtpInput && (
            <div className="mb-4">
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
                required
              />
            </div>
          )}
          {/* {!showOtpInput && (
            <div className="flex justify-between items-center mb-6">
              <button
                type="button"
                onClick={() => {
                  setUsePhone(!usePhone);
                  setIdentifier('');
                }}
                className="text-blue-600 underline focus:outline-none"
              >
                {usePhone ? 'Use email instead' : 'Use phone instead'}
              </button>
            </div>
          )} */}
          <button
            type="submit"
            className={`w-full bg-gray-800 text-white py-3 rounded-lg text-lg font-semibold hover:bg-gray-700 transition duration-300 ${isLoading && 'opacity-50'}`}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : showOtpInput ? 'Verify OTP' : 'Send OTP'}
          </button>
        </form>
        {showOtpInput && (
          <button
            onClick={() => setShowOtpInput(false)}
            className="w-full mt-4 text-blue-600 underline focus:outline-none"
          >
            Back to {usePhone ? 'phone' : 'email'} input
          </button>
        )}
        <div className="text-center mt-6">
          <p className="text-gray-600">
            Are you a vendor?{' '}
            <Link to="/vendor-login" className="text-blue-600 underline">
              Click here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
