import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check for error parameters in the URL hash
    if (location.hash) {
      const hashParams = new URLSearchParams(location.hash.substring(1));
      const errorDescription = hashParams.get('error_description');
      if (errorDescription) {
        setError(errorDescription.replace(/\+/g, ' '));
        // Redirect to login after showing error
        setTimeout(() => {
          navigate('/vendor-login');
        }, 3000);
      }
    }

    // Check if we're in a password reset flow
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session && !location.hash) {
        setError('Invalid or expired reset link. Please request a new password reset.');
        setTimeout(() => {
          navigate('/vendor-login');
        }, 3000);
      }
    };
    checkSession();
  }, [navigate, location]);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);

    // Validate passwords match
    if (newPassword !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    // Validate password strength
    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }

    setLoading(true);

    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;

      setMessage('Password updated successfully! Redirecting to login...');
      
      // Redirect after successful password reset
      setTimeout(() => {
        navigate('/vendor-login');
      }, 2000);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // If there's an error, show a simplified error view
  if (error && !loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
          <div className="flex justify-center mb-6">
            <img
              src="/images/Logo.png"
              alt="Hometown"
              className="w-32 object-contain"
            />
          </div>
          <h2 className="text-xl font-bold text-red-600 mb-4">Reset Link Error</h2>
          <p className="text-gray-600 mb-6">{error}</p>
          <p className="text-gray-500 mb-4">Redirecting to login page...</p>
          <button
            onClick={() => navigate('/vendor-login')}
            className="text-blue-600 hover:text-blue-800 underline"
          >
            Return to Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-6">
          <img
            src="/images/Logo.png"
            alt="Hometown"
            className="w-32 object-contain"
          />
        </div>
        <h2 className="text-center text-blue-800 font-bold text-xl mb-4">
          Reset Password
        </h2>
        <p className="text-center text-gray-600 mb-6">
          Please enter your new password
        </p>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        {message && <p className="text-green-500 text-center mb-4">{message}</p>}

        <form onSubmit={handlePasswordReset} className="space-y-4">
          <div className="mb-4 relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
            >
              {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
            </button>
          </div>

          <div className="mb-4 relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-4 py-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-300 outline-none shadow-sm"
              required
            />
            <button
              type="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
            >
              {showConfirmPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
            </button>
          </div>

          <button
            type="submit"
            className={`w-full bg-gray-800 text-white py-3 rounded-lg text-lg font-semibold hover:bg-gray-700 transition duration-300 ${
              loading && 'opacity-50'
            }`}
            disabled={loading}
          >
            {loading ? 'Updating Password...' : 'Update Password'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword; 