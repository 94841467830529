import { createClient } from '@supabase/supabase-js'
import { loadStripe } from '@stripe/stripe-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const checkAuth = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  
  if (user) {
    const { data: { role } } = await supabase
      .from('users')
      .select('role')
      .eq('id', user.id)
      .single();
    
    if (role === 'vendor') {
      // Query the vendors table for the vendor_id associated with this user
      const { data: vendorData, error } = await supabase
        .from('vendors')
        .select('id')
        .eq('user_id', user.id)
        .single();
      
      if (error || !vendorData) {
        console.error('Error fetching vendor_id:', error);
        return { authenticated: false };
      }

      // Return user, role, authenticated status, and vendor_id for vendors
      return { user, role, authenticated: !!user, vendor_id: vendorData.id };
    }

    // For non-vendor users, return user, role, and authenticated status
    return { user, role, authenticated: !!user };
  }

  // If no user is authenticated
  return { authenticated: false };
};


export const createUserProfile = async (userId, data) => {
  const { data: existingProfile } = await supabase
    .from('users')
    .select()
    .eq('id', userId)
    .single();

  if (!existingProfile) {
    const { error } = await supabase
      .from('users')
      .insert([
        {
          id: userId,
          role: 'customer',
          ...data
        }
      ]);
    return { error };
  }
  
  return { error: null }; 
};

export const createVendorProfile = async (userId, userData) => {
  try {
    
    const { error: userError } = await supabase
      .from('users')
      .insert([{
        id: userId,
        role: 'vendor',
        email: userData.email,
        phone_number: userData.contact_phone,
        first_name: userData.first_name,
        last_name: userData.last_name
      }]);

    if (userError) throw userError;

    // Then create the vendor profile with the correct column names
    const { error: vendorError } = await supabase
      .from('vendors')
      .insert([{
        user_id: userId,
        company_name: userData.company_name,
        first_name: userData.first_name,
        last_name: userData.last_name,  
        contact_email: userData.email,
        contact_phone: userData.contact_phone,
        address: userData.address,
        type: userData.type,
        mascotname: userData.mascotname,
        short_name: userData.short_name,
        secondary_color: '#1c4ed8',
        primary_color: '#1f3a8a',

        
      }]);

    if (vendorError) throw vendorError;

    return { error: null };
  } catch (error) {
    return { error };
  }
};

export const fetchEvents = async () => {
  try {
    const { data, error } = await supabase
      .from('events')
      .select('*')
      .eq('isDeleted', false);
    
    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error fetching events:', error);
    return { data: null, error };
  }
};

export const fetchVendors = async () => {
  try {
    const { data, error } = await supabase
      .from('vendors')
      .select('id, company_name, address'); 


    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error fetching vendors:', error);
    return { data: null, error };
  }
};

export const fetchVendorById = async (vendorId) => {
  try {
    const { data, error } = await supabase
      .from('vendors')
      .select('*')
      .eq('id', vendorId)
      .single();

    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error fetching vendor:', error);
    return { data: null, error };
  }
};

// Fetch events for a specific vendor
export const fetchEventsByVendorId = async (vendorId) => {
  try {
    const { data, error } = await supabase
      .from('events')
      .select('*')
      .eq('vendor_id', vendorId)
      .eq('isDeleted', false);

    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error fetching events:', error);
    return { data: null, error };
  }
};

export const fetchEventById = async (eventId) => {
  try {
    const { data, error } = await supabase
      .from('events')
      .select('*')
      .eq('id', eventId)
      .eq('isDeleted', false)
      .single();

    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error fetching event:', error);
    return { data: null, error };
  }
};

export const fetchTicketsByEventId = async (eventId) => {
  try {
    const { data, error } = await supabase
      .from('tickets')
      .select('*')
      .eq('event_id', eventId)
      .eq('isDeleted', false);

    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error fetching tickets:', error);
    return { data: null, error };
  }
};

export const checkStripeAccountStatus = async (accountId) => {
  try {
    // Step 1: Fetch account details from Stripe
    const response = await fetch(`https://api.stripe.com/v1/accounts/${accountId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        'Stripe-Version': '2020-08-27',
      },
    });

    // Handle HTTP errors
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Stripe API Error: ${errorData.error.message}`);
    }

    const account = await response.json();

    // Step 2: Determine if the account is fully set up
    const isComplete =
      account.details_submitted &&
      account.charges_enabled &&
      account.payouts_enabled &&
      !(account.requirements.currently_due?.length);

    // Step 3: Generate account onboarding link if incomplete
    let accountLink = null;
    if (!isComplete) {
      const linkResponse = await fetch('https://api.stripe.com/v1/account_links', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          'Content-Type': 'application/x-www-form-urlencoded',
          'Stripe-Version': '2020-08-27',
        },
        body: new URLSearchParams({
          account: accountId,
          refresh_url: `${window.location.origin}/vendor-dashboard?tab=settings`,
          return_url: `${window.location.origin}/vendor-dashboard?tab=settings`,
          type: 'account_onboarding',
        }).toString(),
      });

      // Handle HTTP errors for account link
      if (!linkResponse.ok) {
        const errorData = await linkResponse.json();
        throw new Error(`Stripe Account Link Error: ${errorData.error.message}`);
      }

      const linkData = await linkResponse.json();
      accountLink = linkData.url || null;
    }

    // Step 4: Return account status and additional details
    return {
      isComplete,
      requirements: account.requirements,
      chargesEnabled: account.charges_enabled,
      payoutsEnabled: account.payouts_enabled,
      detailsSubmitted: account.details_submitted,
      currentlyDue: account.requirements.currently_due || [],
      pastDue: account.requirements.past_due || [],
      eventuallyDue: account.requirements.eventually_due || [],
      accountLink, // Account onboarding link if applicable
    };
  } catch (error) {
    console.error('Error checking Stripe account status:', error.message);
    throw error;
  }
};


export const createStripeAccount = async (vendorId, email, businessName) => {
  try {
    // First check if vendor already has a Stripe account
    const { data: vendorData, error: vendorError } = await supabase
      .from('vendors')
      .select('stripe_account_id, stripe_account_status')
      .eq('id', vendorId)
      .single();

    if (vendorError) throw vendorError;

    let accountId = vendorData?.stripe_account_id;
    let accountStatus = vendorData?.stripe_account_status;

    // If there's an existing account, check its status with Stripe
    if (accountId) {
      const stripeStatus = await checkStripeAccountStatus(accountId);

      // Update the database with the accurate status
      await supabase
        .from('vendors')
        .update({ 
          stripe_account_status: stripeStatus.isComplete ? 'complete' : 'incomplete'
        })
        .eq('id', vendorId);

      if (stripeStatus.isComplete) {
        return { status: 'complete' };
      }

      // If account exists but is incomplete, create new account link
      const linkResponse = await fetch('https://api.stripe.com/v1/account_links', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          'Content-Type': 'application/x-www-form-urlencoded',
          'Stripe-Version': '2020-08-27'
        },
        body: new URLSearchParams({
          account: accountId,
          refresh_url: `${window.location.origin}/vendor-dashboard?tab=settings`,
          return_url: `${window.location.origin}/vendor-dashboard?tab=settings`,
          type: 'account_onboarding'
        }).toString()
      });

      const accountLink = await linkResponse.json();
      if (accountLink.error) throw accountLink.error;
      return { url: accountLink.url };
    }

    // If no existing account, create new one
    const accountResponse = await fetch('https://api.stripe.com/v1/accounts', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        'Stripe-Version': '2020-08-27'
      },
      body: new URLSearchParams({
        type: 'express',
        country: 'US',
        email: email,
        business_type: 'company',
        'company[name]': businessName,
        'capabilities[card_payments][requested]': 'true',
        'capabilities[transfers][requested]': 'true'
      }).toString()
    });

    const account = await accountResponse.json();
    if (account.error) throw account.error;

    // Create account link for new account
    const linkResponse = await fetch('https://api.stripe.com/v1/account_links', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        'Stripe-Version': '2020-08-27'
      },
      body: new URLSearchParams({
        account: account.id,
        refresh_url: `${window.location.origin}/vendor-dashboard?tab=settings`,
        return_url: `${window.location.origin}/vendor-dashboard?tab=settings`,
        type: 'account_onboarding'
      }).toString()
    });

    const accountLink = await linkResponse.json();
    if (accountLink.error) throw accountLink.error;

    // Update vendor record with new Stripe account ID
    await supabase
      .from('vendors')
      .update({
        stripe_account_id: account.id,
        stripe_account_status: 'pending'
      })
      .eq('id', vendorId);

    return { url: accountLink.url };
  } catch (error) {
    console.error('Error in createStripeAccount:', error);
    throw error;
  }
};

// Create payment intent function
export const createPaymentIntent = async (amount, vendorId, totalTickets) => {
  try {
    

    // Fetch vendor data
    const { data: vendorData, error: vendorError } = await supabase
      .from('vendors')
      .select('stripe_account_id, convenience_fee, stripe_account_status')
      .eq('id', vendorId)
      .single();

  

    if (vendorError) throw vendorError;
    if (!vendorData.stripe_account_id) {
      throw new Error(`Vendor ${vendorId} does not have a connected Stripe account`);
    }
    if (vendorData.stripe_account_status !== 'complete') {
      throw new Error(`Vendor ${vendorId}'s Stripe account setup is incomplete`);
    }

    // Ensure amount is a valid number
    const baseAmount = parseFloat(amount);
   
    
    if (isNaN(baseAmount)) {
      throw new Error('Invalid amount provided');
    }

    // Calculate amounts
    const convenienceFee = vendorData.convenience_fee || 1.30;
  
    
    const totalFees = convenienceFee * totalTickets;
   
    
    // Convert to cents and ensure we have whole numbers
    const amountInCents = Math.round((baseAmount + totalFees) * 100);
    const feeAmountInCents = Math.round(totalFees * 100);


    // Create payment intent
    const response = await fetch('https://api.stripe.com/v1/payment_intents', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        'Stripe-Version': '2020-08-27',
      },
      body: new URLSearchParams({
        'amount': amountInCents,
        'currency': 'usd',
        'automatic_payment_methods[enabled]': 'true',
        'application_fee_amount': feeAmountInCents,
        'transfer_data[destination]': vendorData.stripe_account_id,
        'on_behalf_of': vendorData.stripe_account_id,
      }).toString(),
    });

    const paymentIntent = await response.json();

    if (!response.ok || paymentIntent.error) {
      console.error('Stripe API Error:', paymentIntent.error || response.statusText);
      throw new Error(paymentIntent.error?.message || 'Failed to create PaymentIntent');
    }

    return {
      clientSecret: paymentIntent.client_secret,
      paymentIntentId: paymentIntent.id,
      totalAmount: amountInCents,
      feeAmount: feeAmountInCents,
      accountId: vendorData.stripe_account_id,
    };
  } catch (error) {
    console.error('Error creating payment intent:', error.message);
    throw error;
  }
};


export const deleteEvent = async (eventId) => {
  try {
    // First check if any tickets were ever sold for this event
    const { count: soldTicketsCount, error: countError } = await supabase
      .from('tickets_sold')
      .select('*', { count: 'exact', head: true })
      .eq('event_id', eventId);

    if (countError) throw countError;

    // If tickets were ever sold, prevent deletion
    if (soldTicketsCount > 0) {
      return { 
        error: { 
          message: 'Cannot delete event: Tickets have been sold for this event' 
        } 
      };
    }

    // Instead of deleting, update isDeleted flag to true
    const { error: eventError } = await supabase
      .from('events')
      .update({ isDeleted: true })
      .eq('id', eventId);

    if (eventError) throw eventError;

    // Also mark all associated tickets as deleted
    const { error: ticketsError } = await supabase
      .from('tickets')
      .update({ isDeleted: true })
      .eq('event_id', eventId);

    if (ticketsError) throw ticketsError;

    return { error: null };
  } catch (error) {
    console.error('Error marking event as deleted:', error);
    return { error };
  }
};

export const deleteTicket = async (ticketId) => {
  try {
    // Instead of deleting, update the isDeleted flag to true
    const { error: ticketError } = await supabase
      .from('tickets')
      .update({ isDeleted: true })
      .eq('id', ticketId);

    if (ticketError) throw ticketError;

    return { error: null };
  } catch (error) {
    console.error('Error marking ticket as deleted:', error);
    return { error };
  }
};

export const recordTicketSale = async (ticketSales) => {
  try {
    const { data, error } = await supabase
      .from('tickets_sold')
      .insert(ticketSales)
      .select();

    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error recording ticket sale:', error);
    return { data: null, error };
  }
};

export const fetchUserTickets = async (userEmail) => {
  try {
    const { data, error } = await supabase
      .from('tickets_sold')
      .select(`
        *,
        events:event_id (
          title,
          location,
          start_date,
          event_type,
          gender_type,
          opponent
        ),
        vendors:vendor_id (
          company_name,
          short_name,
          primary_color,
          secondary_color,
          logoURL
        )
      `)
      .eq('email', userEmail)
      .order('purchase_date', { ascending: false });

    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error fetching user tickets:', error);
    return { data: null, error };
  }
};

