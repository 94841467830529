import React, { useState, useEffect } from 'react';
import { FaCalendarAlt, FaTachometerAlt, FaSignOutAlt, FaCog, FaBars, FaTimes } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { useAuth } from '../AuthContext';
import DashboardTab from '../components/tabs/DashboardTab';
import EventsTab from '../components/tabs/EventsTab';
import SettingsTab from '../components/tabs/SettingsTab';

function VendorDashboard() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location.state?.activeTab || 'dashboard'
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  // Update active tab when location state changes
  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
      // Clear the state after using it
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, navigate]);

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    navigate('/vendor-login');
    window.location.reload();
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return <DashboardTab />;
      case 'events':
        return <EventsTab />;
      case 'settings':
        return <SettingsTab vendorId={isAuthenticated.vendor_id} />;
      default:
        return null;
    }
  };

  const NavItem = ({ icon: Icon, label, tabName }) => (
    <button
      onClick={() => {
        setActiveTab(tabName);
        setIsSidebarOpen(false);
      }}
      className={`flex items-center w-full px-6 py-4 text-left transition-colors duration-200 ${
        activeTab === tabName 
          ? 'bg-blue-600 text-white' 
          : 'text-gray-600 hover:bg-gray-100'
      }`}
    >
      <Icon className={`w-5 h-5 mr-3 ${activeTab === tabName ? 'text-white' : 'text-gray-500'}`} />
      <span className="font-medium">{label}</span>
    </button>
  );

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Mobile Sidebar Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <div
        className={`fixed lg:static inset-y-0 left-0 w-64 bg-white shadow-lg transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 transition-transform duration-200 ease-in-out z-50`}
      >
        <div className="flex flex-col h-full">
          {/* Logo Section */}
          <div className="p-6 border-b flex justify-center">
            <h1 className="text-2xl font-bold text-center text-gray-800">Valley Tickets</h1>
            {/* <img
              src="/images/Logo.png"
              alt="Logo"
              className="w-32 mx-auto mt-4"
            /> */}
          </div>

          {/* Navigation Items */}
          <nav className="flex-1 py-4">
            <NavItem icon={FaTachometerAlt} label="Dashboard" tabName="dashboard" />
            <NavItem icon={FaCalendarAlt} label="Events" tabName="events" />
            <NavItem icon={FaCog} label="Settings" tabName="settings" />
          </nav>

          {/* Sign Out Button */}
          <button
            onClick={handleSignOut}
            className="flex items-center px-6 py-4 text-red-600 hover:bg-red-50 transition-colors duration-200"
          >
            <FaSignOutAlt className="w-5 h-5 mr-3" />
            <span className="font-medium">Sign Out</span>
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top Bar */}
        <header className="bg-white shadow-sm">
          <div className="flex items-center justify-between px-6 py-4">
            <div className="flex items-center">
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="lg:hidden p-2 rounded-md hover:bg-gray-100"
              >
                {isSidebarOpen ? (
                  <FaTimes className="w-6 h-6 text-gray-600" />
                ) : (
                  <FaBars className="w-6 h-6 text-gray-600" />
                )}
              </button>
              <h1 className="ml-4 text-xl font-semibold text-gray-800">
                {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
              </h1>
            </div>
          </div>
        </header>

        {/* Content Area */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          {renderContent()}
        </main>
      </div>
    </div>
  );
}

export default VendorDashboard;
